<template>
<div class="bg">
  <header-block />
  <div class="container container--step">
    <div class="steps__block">
      <h1 class="title title--step">
        как сделать валентинку: 4 простых шага
      </h1>
      <div class="steps">
        <div class="step">
          <p class="step__num">
            1
          </p>
          <p class="step__desc">
            Выбрать формат
          </p>
          <p class="step__txt">
            Перейдите в формат «Квадрат», «Открытка» или «Сториз» с главной страницы.
          </p>
        </div>
        <div class="step">
          <p class="step__num">
            2
          </p>
          <p class="step__desc">
            Выберите шаблон
          </p>
          <p class="step__txt">
            Выберите подходящий шаблон валентинки из огромной коллекции красивых дизайнов.
          </p>
        </div>
        <div class="step">
          <p class="step__num">
            3
          </p>
          <p class="step__desc">
            Добавьте фото
          </p>
          <p class="step__txt">
            Добавьте собственные изображения
          </p>
        </div>
        <div class="step">
          <p class="step__num">
            4
          </p>
          <p class="step__desc">
            Загрузить и поделиться
          </p>
          <p class="step__txt">
            Когда открытка будет готова, скачайте ее и отправьте любимым.
          </p>
        </div>
      </div>
      <div class="button__block">
        <router-link to="/template" class="button">
          Сделать валентинку
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'Home',
};
</script>
